<template>
    <div class="Calendar" :class="{ 'is-loading': isFetchingData }">
        <Headline class="Headline">
            <h1 class="Title">Ihr Schichtplanner</h1>
            <!-- <p class="Description">Hier sehen Sie alle Termine Ihres Unternehmens.</p> -->
        </Headline>
        <!-- <Toolbar class="Toolbar">
            <div></div>
            <div>
                <Button @onClick="createEvent" :isLoading="isFetchingData">
                    Neuen Termin erstellen
                </Button>
                <Download
                    :title="isMobile ? 'CSV' : 'Download CSV'"
                    type="csv"
                    @onDownload="handleDownload"
                />
            </div>
        </Toolbar> -->
        <div class="PlannerWrap">
            <div v-if="!isFetchingData" class="MapWrapper">
                <l-map
                    ref="mapObject"
                    :zoom="zoom"
                    :center="center"
                    style="height: 300px; width: 100%;"
                >
                    <l-tile-layer :url="tileUrl" :attribution="attribution"></l-tile-layer>

                    <l-marker
                        v-for="(location, index) in employeeLocations"
                        ref="marker"
                        :key="index"
                        :lat-lng="[location.lat, location.lng]"
                        @click="onMarkerClick(location)"
                    >
                        <l-popup>{{ location.name }}</l-popup>
                    </l-marker>
                </l-map>
            </div>
            <AssigneeList
                :assignees="employeeLocations"
                :selectedAssignee="selectedAssignee"
                @assignee-click="onAssigneeClick"
            />

            <div style="margin: 10px 0">
                <Schedular
                    :events="driverSchedule"
                    :resources="currentCars"
                    :assignees="currentEmployees"
                    :variant="variant"
                    @onSaveAsDefault="handleSaveAsDefault"
                    @onLoadDefault="loadDefaultSchedule"
                    @onToggle="handleToggle"
                    @onEventSave="handleEventSave"
                    @onEventDelete="handleDeleteEvent"
                />
            </div>
        </div>
        <!--     <div class="CalendarWrapper">
            <div class="EventList">
                <div>
                    <Card v-for="event in events" :key="event.id" class="EventCard">
                        <p>{{ event.title }}</p>
                        <div class="EventDetails">
                            <span class="Date">{{
                                format(event.startDate, 'dd.MM.yyyy HH:mm')
                            }}</span>
                            <span class="Date" v-if="event.endDate">-</span>
                            <span class="Date" v-if="event.endDate">{{
                                format(event.endDate, 'dd.MM.yyyy HH:mm')
                            }}</span>
                            <span class="Description">{{ event.description }}</span>
                            <span class="ButtonWrap">
                                <Button
                                    @onClick="deleteEvent(event.id)"
                                    variant="minimal"
                                    size="small"
                                    class="DeleteButton"
                                >
                                    Löschen
                                </Button>
                                <Button @onClick="viewEvent(event)" variant="minimal" size="small">
                                    Bearbeiten
                                </Button>
                            </span>
                        </div>
                    </Card>
                </div>
            </div>
           <Calendar
                :events="events"
                @on-click-date="createEvent"
                @on-click-item="viewEvent"
                @on-date-selection-end="createEventRange"
                @on-date-selection="createGhostEventRange"
                @on-drop-on-date="moveEvent"
                class="CalendarWidget"
            />
        </div>-->
        <!-- <Modal
            :show="isModalOpen"
            :title="modalTitle"
            :action="{ text: 'Speichern', color: 'green', callback: handleSaveEvent }"
            :cancel="{ text: 'Abbrechen', callback: handleCancelEvent }"
            :isLoading="isSavingEvent"
            @onModalClose="isModalOpen = false"
            class="EventModal"
        >
            <p>{{ modalDescription }}</p>
            <br />
            <div class="InputWrap">
                <label for="title">Titel</label>
                <input class="Input" id="title" v-model="event.title" required />
                <span class="Hint">Fügen Sie hier einen Titel hinzu.</span>
            </div>
            <div class="InputWrap">
                <label for="description">Beschreibung</label>
                <textarea
                    class="Input"
                    id="description"
                    v-model="event.description"
                    required
                ></textarea>
                <span class="Hint">Fügen Sie hier eine Beschreibung hinzu.</span>
            </div>
            <div class="InputWrap-Date">
                <div class="InputWrap">
                    <label for="startDate">Startdatum</label>
                    <input
                        class="Input"
                        id="startDate"
                        type="datetime-local"
                        v-model="formattedStartDate"
                        required
                    />
                </div>
                <div>
                    <Button
                        v-if="!withEndDate"
                        @onClick="withEndDate = !withEndDate"
                        variant="minimal"
                        size="small"
                    >
                        Enddatum hinzufügen
                    </Button>
                </div>
                <div class="InputWrap" v-if="withEndDate">
                    <label for="endDate">Enddatum</label>
                    <input
                        class="Input"
                        id="endDate"
                        type="datetime-local"
                        v-model="formattedEndDate"
                        required
                    />
                </div>
            </div>
            <div class="InputWrap">
                <label for="recurrence">Wiederholung</label>
                <select class="Input" id="recurrence" v-model="event.recurrence">
                    <option value="none">Keine</option>
                    <option value="monthly">Monatlich</option>
                    <option value="yearly">Jährlich</option>
                </select>
                <span class="Hint">Soll der Termin wiederholt werden?</span>
            </div>
            <div v-if="event.recurrence === 'monthly'">
                <span class="">
                    Dieser Termin wird am
                    {{ format(addMonths(event.startDate, 1), 'dd.MM.yyyy') }}
                </span>
            </div>
            <div v-if="event.recurrence === 'yearly'">
                <span class="">
                    Dieser Termin wird am
                    {{ format(addMonths(event.startDate, 12), 'dd.MM.yyyy') }}
                </span>
            </div>
        </Modal> -->
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import L from 'leaflet';
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

import {
    addDays,
    subDays,
    format,
    formatISO,
    addMonths,
    isSameHour,
    isSameDay,
    setHours,
    addHours,
} from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import {
    loadDriverScheduleFromLocalStorage,
    saveDriverScheduleToLocalStorage,
    deleteDriverScheduleEventFromLocalStorage,
    saveDefaultScheduleToLocalStorage,
    loadDefaultScheduleFromLocalStorage,
} from '@/lib/localStorage';

import AssigneeList from '@/components/Schedular/components/AssigneeList.vue';
// import Calendar from '@/components/widgets/Calendar.vue';
import Headline from '@/components/Headline';
import Modal from '@/components/widgets/Modal';
import Card from '@/components/Card';
import Button from '@/components/widgets/Button';
import Toolbar from '@/components/Toolbar';
import Download from '@/components/widgets/Download';
import Schedular from '@/components/Schedular';

export default {
    name: 'CalendarPage',
    components: {
        // Button,
        // Calendar,
        Headline,
        // Modal,
        // Card,
        // Toolbar,
        // Download,
        Schedular,
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        AssigneeList,
    },
    data() {
        return {
            withEndDate: false,
            addMonths,
            notice: '',
            driverSchedule: [],
            defaultPattern: [],
            drivers: JSON.parse(localStorage.getItem('drivers')),
            isModalOpen: false,
            isFetchingData: false,
            isSavingEvent: false,
            variant: 'day-night',
            currentCars: [],
            event: {
                title: '',
                description: '',
                startDate: null,
                endDate: null,
                recurrence: 'none',
            },
            center: [51.1657, 10.4515],
            zoom: 6,
            tileUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:
                'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            employeeLocations: [],
            events: [
                {
                    id: 1,
                    title: 'Event 1',
                    description: 'Description 1',
                    startDate: addDays(new Date(), 1),
                    endDate: addDays(new Date(), 3),
                },
                {
                    id: 2,
                    title: 'Event 2',
                    description: 'Description 2',
                    startDate: addDays(new Date(), 2),
                    endDate: addDays(new Date(), 2),
                },
                {
                    id: 3,
                    title: 'Event 3',
                    description: 'Description 3',
                    startDate: addDays(new Date(), 3),
                    endDate: addDays(new Date(), 3),
                },
                {
                    id: 11,
                    title: 'Event 1',
                    description: 'Description 1',
                    startDate: subDays(new Date(), 1),
                    endDate: subDays(new Date(), 3),
                },
                {
                    id: 12,
                    title: 'Event 2',
                    description: 'Description 2',
                    startDate: subDays(new Date(), 2),
                    endDate: subDays(new Date(), 2),
                },
                {
                    id: 13,
                    title: 'Event 3',
                    description: 'Description 3',
                    startDate: subDays(new Date(), 3),
                },
            ],
            selectedAssignee: null,
        };
    },
    computed: {
        ...mapGetters({
            employees: 'employees',
            cars: 'cars',
        }),
        currentEmployees() {
            const employees = this.employees
                .filter(e => e.isVisible && !e.isSystemDriver)
                .map(e => ({
                    ...e,
                    id: e.uuid,
                    value: e.name,
                }))
                .sort((a, b) => a.value.localeCompare(b.value));

            return employees;
        },

        formattedStartDate: {
            get() {
                return this.event.startDate
                    ? formatISO(this.event.startDate, { representation: 'date' }) +
                          'T' +
                          format(this.event.startDate, 'HH:mm')
                    : '';
            },
            set(value) {
                this.event.startDate = value ? new Date(value) : null;
            },
        },
        formattedEndDate: {
            get() {
                return this.event.endDate
                    ? formatISO(this.event.endDate, { representation: 'date' }) +
                          'T' +
                          format(this.event.endDate, 'HH:mm')
                    : this.formattedStartDate;
            },
            set(value) {
                this.event.endDate = value ? new Date(value) : null;
            },
        },
        modalTitle() {
            return this.event.id ? this.event.title : 'Event erstellen';
        },
        modalDescription() {
            return this.event.id
                ? 'Bearbeiten Sie hier die Daten des Events.'
                : 'Fügen Sie hier die Daten des Events hinzu.';
        },
        employeesLocation() {
            return this.employees;
        },
    },
    methods: {
        ...mapActions(['getEmployees', 'getCars']),
        format,
        onAssigneeClick(assignee) {
            console.log('assignee', assignee.uuid);
            console.log('selectedAssignee', this.selectedAssignee?.uuid);
            if (this.selectedAssignee && this.selectedAssignee.uuid === assignee.uuid) {
                this.selectedAssignee = null;
                return;
            }
            this.selectedAssignee = assignee;
            console.log('assignee', assignee);
            if (assignee.lat && assignee.lng) {
                this.center = [assignee.lat, assignee.lng];
                this.zoom = 13;
                // this.$nextTick(() => {
                //     this.$refs.mapObject.setView(this.center, 12);
                // });

                this.$nextTick(() => {
                    const map = this.$refs.mapObject.mapObject;
                    console.log(map);
                    map.setView(this.center, 13);
                    // Iterate through marker references to find the correct one
                    const markers = this.$refs.marker;

                    if (!Array.isArray(markers)) {
                        console.error('Markers array was not available.');
                        return;
                    }

                    const marker = markers.find(m => {
                        if (
                            m.$props &&
                            m.$props.latLng[0] === assignee.lat &&
                            m.$props.latLng[1] === assignee.lng
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    });

                    if (marker && marker.mapObject) {
                        marker.mapObject.openPopup();
                    } else {
                        console.error('Marker not found for assignee.');
                    }
                });
            }
        },

        onMarkerClick(assignee) {
            this.selectedAssignee = assignee;
            this.scrollToAssignee(assignee);
        },

        scrollToAssignee(assignee) {
            this.$nextTick(() => {
                const element = document.querySelector(`.AssigneeItem[data-id="${assignee.id}"]`);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            });
        },
        async geocodeAddress(streetOne, zipCode) {
            try {
                const response = await axios.get(
                    `https://nominatim.openstreetmap.org/search?street=${encodeURIComponent(
                        streetOne,
                    )}&postalcode=${encodeURIComponent(
                        zipCode,
                    )}&country=germany&format=json&limit=1`,
                );

                if (response?.data?.length > 0) {
                    const { lat, lon } = response.data[0];
                    return { lat: parseFloat(lat), lng: parseFloat(lon) };
                }
            } catch (error) {
                console.error('Error fetching geocode data:', error);
            }

            return null;
        },

        async fetchEmployeeLocations() {
            const locations = [];
            // check if we have the data in local storage and if the data has the same length as the employees and streetOne and zipCode still the same
            const storedLocations = JSON.parse(localStorage.getItem('employeeLocations'));
            if (storedLocations) {
                this.employeeLocations = storedLocations;
                return;
            }

            for (const employee of this.employees) {
                if (employee.streetOne && employee.zipCode) {
                    const coords = await this.geocodeAddress(employee.streetOne, employee.zipCode);

                    if (coords) {
                        locations.push({
                            name: employee.name,
                            ...employee,
                            ...coords,
                        });
                    }
                }
            }
            this.employeeLocations = locations;
            // store in local storage
            localStorage.setItem('employeeLocations', JSON.stringify(locations));
        },

        handleToggle() {
            this.variant = this.variant === 'day-night' ? '24h' : 'day-night';
        },

        handleDownload() {},
        handleEventSave(event) {
            let schedule = [...this.driverSchedule]; // Make a copy of the current schedule

            const index = schedule.findIndex(e => e.id === event.id);

            if (index === -1) {
                // New event
                const newEvent = {
                    id: uuidv4(), // Generate unique ID
                    ...event,
                };

                schedule.unshift(newEvent); // Add new event to start of the driverSchedule
            } else {
                // Update existing event
                schedule.splice(index, 1, { ...event }); // Replace existing event with updated event
            }

            saveDriverScheduleToLocalStorage(schedule); // Save updated schedule to localStorage
            this.driverSchedule = schedule; // Update the component's local `driverSchedule` data

            this.isModalOpen = false; // Close modal if there's one open
        },

        handleSaveAsDefault() {
            saveDefaultScheduleToLocalStorage(this.driverSchedule);
            alert('Der aktuelle Schichtplan wurde als Standard gespeichert.');
        },

        loadDefaultSchedule() {
            const defaultSchedule = loadDefaultScheduleFromLocalStorage();
            if (defaultSchedule.length > 0) {
                this.driverSchedule = defaultSchedule;
            } else {
                alert('Es wurde kein Standard-Schichtplan gefunden.');
            }
        },

        handleDeleteEvent({ id }) {
            const confirmation = confirm('Wollen Sie diesen Termin wirklich löschen?');
            if (!confirmation) return;
            const updatedSchedule = deleteDriverScheduleEventFromLocalStorage(id);
            this.driverSchedule = updatedSchedule;
        },
        createGhostEventRange({ startDate, endDate }) {
            this.event = {
                title: 'Event',
                description: '',
                startDate,
                endDate,
            };

            // create ghost event if not exists
            const ghostIndex = this.events.findIndex(e => e.id === 'ghost');
            if (ghostIndex === -1) {
                this.events.push({
                    ...this.event,
                    id: 'ghost',
                });
            }

            // adjust ghost event to new range
            const itemIndex = this.events.findIndex(e => e.id === 'ghost');
            this.events.splice(itemIndex, 1, {
                ...this.event,
                id: 'ghost',
            });
        },
        createEventRange({ startDate, endDate }) {
            this.event = {
                title: '',
                description: '',
                startDate,
                endDate,
            };
            this.isModalOpen = true;
        },
        createEvent(day = new Date()) {
            this.event = {
                title: '',
                description: '',
                startDate: day,
                endDate: day,
            };
            this.isModalOpen = true;
        },
        viewEvent({ id }) {
            const event = this.events.find(e => e.id === id);
            this.event = event;
            this.isModalOpen = true;
        },
        moveEvent({ item, date }) {
            const itemIndex = this.events.findIndex(e => e.id === item.id);
            this.events[itemIndex].startDate = date;
        },
        handleCancelEvent() {
            this.isModalOpen = false;
            const ghostIndex = this.events.findIndex(e => e.id === 'ghost');
            if (ghostIndex !== -1) {
                this.events.splice(ghostIndex, 1);
            }
        },
        deleteEvent(eventId) {
            const confirmation = confirm('Wollen Sie diesen Termin wirklich löschen?');
            if (!confirmation) return;
            const eventIndex = this.events.findIndex(e => e.id === eventId);
            if (eventIndex !== -1) {
                this.events.splice(eventIndex, 1);
                // Perform additional operations like API call to delete the event from the database
            }
        },
        handleSaveEvent() {
            this.isSavingEvent = true;
            let rruleString = '';
            if (this.event.recurrence === 'monthly') {
                rruleString = new RRule({
                    freq: RRule.MONTHLY,
                    bymonthday: this.event.dayOfMonth,
                    dtstart: new Date(this.event.startDate),
                    until: new Date(this.event.endDate),
                }).toString();
            } else if (this.event.recurrence === 'yearly') {
                rruleString = new RRule({
                    freq: RRule.YEARLY,
                    bymonth: this.event.monthOfYear,
                    bymonthday: this.event.dayOfMonthYearly,
                    dtstart: new Date(this.event.startDate),
                    until: new Date(this.event.endDate),
                }).toString();
            }
            const eventToSave = { ...this.event, rrule: rruleString };

            setTimeout(() => {
                this.isSavingEvent = false;
                this.isModalOpen = false;

                if (this.event.id) {
                    // remove ghost event
                    const ghostIndex = this.events.findIndex(e => e.id === 'ghost');
                    if (ghostIndex !== -1) {
                        this.events.splice(ghostIndex, 1);
                    }

                    const itemIndex = this.events.findIndex(e => e.id === this.event.id);
                    this.events.splice(itemIndex, 1, {
                        ...eventToSave,
                    });
                } else {
                    this.events.push({
                        ...eventToSave,
                        id: this.events.length + 1,
                    });
                }
            }, 1000);
        },
        centerMap() {
            if (this.employeeLocations.length > 0) {
                const bounds = L.latLngBounds(this.employeeLocations.map(l => [l.lat, l.lng]));
                this.center = bounds.getCenter();
                this.zoom = 9;
            }
        },
    },

    async mounted() {
        this.isFetchingData = true;
        await this.getEmployees();
        await this.getCars();
        await this.fetchEmployeeLocations();

        if (!this.cars?.length) return [];
        this.currentCars = [...this.cars]
            .filter(car => car.currentInstallation && car.isVisible)
            .map(car => ({
                ...car,
                id: car.uuid,
                value: car.currentInstallation.licenseNumber,
                description: car.currentInstallation.driver,
            }));

        this.driverSchedule = loadDriverScheduleFromLocalStorage();

        // for (let i = 0; i < this.cars.length; i++) {
        //     const car = this.cars[i];
        //     // assign a driver to a car if it has one for the current day
        //     const driver = this.currentEmployees[
        //         Math.floor(Math.random() * this.currentEmployees.length)
        //     ];
        //     const hasDriver = this.driverSchedule.find(
        //         e =>
        //             e.resourceId === car.licenseNumber &&
        //             isSameHour(e.startDate, new Date()) &&
        //             isSameDay(e.startDate, new Date()),
        //     );
        //     const startDate = setHours(
        //         addDays(
        //             new Date(),
        //             Math.random() < 0.5
        //                 ? Math.floor(Math.random() * 7)
        //                 : Math.floor(Math.random() * 7),
        //         ),
        //         Math.random() * 24,
        //     );
        //     this.driverSchedule.push({
        //         id: uuidv4(),
        //         startDate: startDate,
        //         endDate: addHours(startDate, Math.random() < 0.5 ? 3 : 6),
        //         resourceId: car.id,
        //         title: driver.value,
        //         description: driver.description,
        //     });
        // }
        // axios
        //     .get('/api/events', {
        //         params: {
        //             startAt: formatISO(this.selectedFrom, { representation: 'date' }),
        //             endAt: formatISO(this.selectedTo, { representation: 'date' }),
        //         },
        //     })
        //     .then(({ data }) => {
        //         this.events = data;
        //     })
        //     .finally(() => {
        //         this.isFetchingData = false;
        //     });

        console.log('driverSchedule', this.driverSchedule);
        this.isFetchingData = false;
        this.$emit('onFinishLoading');
        this.centerMap();
    },
};
</script>

<style lang="scss">
.Calendar {
    @extend %contentWrapper;
    @extend %page;

    .Headline {
        margin-bottom: 0px;
    }

    .Title {
        margin-bottom: 10px;
    }

    .Toolbar {
    }

    .PlannerWrap {
        display: flex;
        flex-direction: column;
    }

    .MapWrapper {
        border-radius: 10px;
        overflow: hidden;
        border: 1px solid var(--color-border);
        margin-bottom: 20px;
        height: 300px; // Adjust map height as needed
        width: 100%;
        position: relative;
        z-index: 1;
    }

    .CalendarWrapper {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: flex-start;
        margin: 0 auto;

        @media screen and (max-width: breakpoint(tabletLandscape)) {
            flex-direction: column;
        }
    }

    .CalendarWidget {
        flex: 1;
        height: calc(100vh - 260px);
        width: 100%;

        @media screen and (max-width: breakpoint(tabletLandscape)) {
            height: 500px;
            flex: unset;
        }
    }

    .EventList {
        flex: 0.35;
        width: 100%;
        max-height: calc(100vh - 260px);
        overflow-y: auto;

        .Date,
        .Description {
            font-size: 14px;
            display: block;
            margin-top: 5px;
        }

        .Description {
            width: 100%;
        }

        @media screen and (max-width: breakpoint(tabletLandscape)) {
            max-height: 100%;
        }
    }

    .EventCard {
        padding: 16px;
        margin-bottom: 10px;
        position: relative;

        &:hover {
            .DeleteButton {
                opacity: 1;
            }
        }
    }

    .EventDetails {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 5px;
    }

    .ButtonWrap {
        display: flex;
        margin-top: 5px;
        gap: 4px;
        margin-left: auto;

        .DeleteButton {
            color: var(--color-red-light);
            border-color: transparent;
        }
    }

    .InputWrap {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        label {
            font-weight: bold;
            margin-bottom: 5px;
        }

        .Input,
        textarea {
            padding: 10px;
            border-radius: 5px;
            border: 1px solid var(--color-text-inactive);
            background: var(--color-white);

            &:focus {
                outline: none;
                border-color: color(blue-light);
            }
        }

        textarea {
            min-height: 100px;
        }

        .Hint {
            font-size: 12px;
            line-height: 1.25;
            color: #595959;
            margin-top: 5px;
        }
    }

    .EventModal {
    }
    .InputWrap-Date {
        display: flex;
        gap: 10px;
        align-items: center;
    }
}
</style>
