var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "scheduler-container"
  }, [_c('Toolbar', [_c('div', [_c('NavigationButtons', {
    on: {
      "navigate": _vm.navigate
    }
  }), _c('CurrentViewIndicator', {
    attrs: {
      "currentDate": _vm.currentDate
    }
  })], 1), _c('div')]), _c('div', {
    staticClass: "scheduler-body"
  }, [_c('ResourceList', {
    attrs: {
      "resources": _vm.resources
    }
  }), _c('TimeGrid', {
    attrs: {
      "variant": _vm.variant,
      "events": _vm.events,
      "resources": _vm.resources,
      "currentDate": _vm.currentDate,
      "startDate": _vm.currentDate
    },
    on: {
      "onCreateEvent": _vm.handleCreateEvent,
      "onEditEvent": _vm.handleEditEvent,
      "onUpdateEvent": _vm.handleUpdateEvent,
      "onDeleteEvent": _vm.handleDeleteEvent
    }
  })], 1), _c('EventModal', {
    attrs: {
      "isModalOpen": _vm.isModalOpen,
      "event": _vm.event
    },
    on: {
      "onCancel": function onCancel($event) {
        _vm.isModalOpen = false;
      },
      "onSave": _vm.handleEventSave
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }