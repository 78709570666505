var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "AssigneeContainer"
  }, [_c('ul', {
    staticClass: "AssigneeList"
  }, [_c('draggable', {
    staticClass: "list-group AssigneeList",
    attrs: {
      "list": _vm.list,
      "disabled": !_vm.enabled,
      "ghost-class": "ghost",
      "group": {
        name: 'events',
        pull: 'clone',
        put: false
      },
      "move": _vm.checkMove,
      "clone": function clone(original) {
        return original;
      }
    },
    on: {
      "start": function start($event) {
        _vm.dragging = true;
      },
      "end": function end($event) {
        _vm.dragging = false;
      }
    }
  }, _vm._l(_vm.list, function (element) {
    return _c('div', {
      key: element.uuid,
      staticClass: "list-group-item AssigneeItem event",
      class: [{
        isHighlighted: _vm.selectedAssignee && _vm.selectedAssignee.uuid === element.uuid
      }, {
        'is-day-driver': !element.hasNightShiftPreference
      }],
      attrs: {
        "data-id": element.uuid,
        "data-name": element.name
      },
      on: {
        "click": function click($event) {
          return _vm.onAssigneeClick(element);
        }
      }
    }, [_c('span', [_vm._v(" " + _vm._s(element.name) + " ")])]);
  }), 0)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }