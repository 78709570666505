<template>
    <div class="scheduler-container">
        <Toolbar>
            <div>
                <NavigationButtons @navigate="navigate" />
                <CurrentViewIndicator :currentDate="currentDate" />
            </div>
            <div>
                <!-- <ToggleInput @onToggle="handleToggle" :value="true">
                    <template #left>
                        Tag
                    </template>
                    <template #right>
                        Woche
                    </template>
                </ToggleInput> -->
                <!-- <Button variant="basic" @onClick="handleLoadDefaultSchedule"
                    >Lade Standard-Belegung</Button
                >
                <Button variant="basic" @onClick="handleSaveAsDefault"
                    >Als Standard speichern</Button
                > -->
            </div>
        </Toolbar>
        <div class="scheduler-body">
            <ResourceList :resources="resources" />
            <TimeGrid
                :variant="variant"
                :events="events"
                :resources="resources"
                :currentDate="currentDate"
                :startDate="currentDate"
                @onCreateEvent="handleCreateEvent"
                @onEditEvent="handleEditEvent"
                @onUpdateEvent="handleUpdateEvent"
                @onDeleteEvent="handleDeleteEvent"
            />
        </div>
        <EventModal
            :isModalOpen="isModalOpen"
            :event="event"
            @onCancel="isModalOpen = false"
            @onSave="handleEventSave"
        />
    </div>
</template>

<script>
import { startOfWeek, addDays, subDays, addWeeks, subWeeks } from 'date-fns';
import Toolbar from '@/components/Toolbar.vue';
import NavigationButtons from './components/NavigationButtons.vue';
import CurrentViewIndicator from './components/CurrentViewIndicator.vue';
// import ViewSwitcher from './components/ViewSwitcher.vue';
// import ModalTriggerButton from './components/ModalTriggerButton.vue';
import ResourceList from './components/ResourceList.vue';
import TimeGrid from './components/TimeGrid.vue';
import EventModal from './components/EventModal.vue';
import Button from '@/components/widgets/Button.vue';

export default {
    name: 'Scheduler',
    components: {
        // Button,
        Toolbar,
        NavigationButtons,
        CurrentViewIndicator,
        // ViewSwitcher,
        // ModalTriggerButton,
        ResourceList,
        TimeGrid,
        EventModal,
    },
    props: {
        variant: {
            type: String,
            default: 'primary',
        },
        resources: {
            type: Array,
            required: true,
        },
        events: {
            type: Array,
            required: true,
        },
        assignees: {
            type: Array,
            required: true,
        },
    },
    watch: {
        events: {
            handler: function(newVal, oldVal) {
                console.log('events', newVal);
            },
            deep: true,
        },
        resources: {
            handler: function(newVal, oldVal) {
                console.log('resources', newVal);
            },
            deep: true,
        },
    },
    data() {
        return {
            event: {
                title: '',
                startDate: '',
                endDate: '',
                resourceId: '',
                driverId: '',
            },
            startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
            currentDate: new Date(),
            currentView: 'week', // 'day', 'week', 'month'
            isModalOpen: false,
        };
    },

    methods: {
        handleSaveAsDefault() {
            this.$emit('onSaveAsDefault');
        },
        handleLoadDefaultSchedule() {
            this.$emit('onLoadDefault');
        },
        handleToggle(value) {
            // console.log('handleToggle', value);
            this.$emit('onToggle', value);
        },
        handleEventSave(event) {
            this.isModalOpen = false;
            this.$emit('onEventSave', event);
        },
        handleDeleteEvent(event) {
            this.$emit('onEventDelete', event);
        },
        handleUpdateEvent(event) {
            this.$emit('onEventSave', event);
        },
        handleEditEvent({ id, description, endDate, startDate, resourceId, title }) {
            this.event = {
                id,
                title,
                startDate,
                endDate,
                resourceId,
            };
            this.isModalOpen = true;
            // this.$emit('onEventEdit', this.event);
        },
        handleCreateEvent({ description, endDate, startDate, resourceId, title }) {
            // console.log('handleCreateEvent', description, endDate, startDate, resourceId, title);
            this.event = {
                title,
                startDate,
                endDate,
                resourceId,
            };
            this.isModalOpen = true;
        },
        navigate({ action }) {
            switch (action) {
                case 'today':
                    this.currentDate = new Date();
                    break;
                case 'previous':
                    this.currentDate =
                        this.variant === 'day-night'
                            ? subWeeks(this.currentDate, 1)
                            : subDays(this.currentDate, 1);
                    break;
                case 'next':
                    this.currentDate =
                        this.variant === 'day-night'
                            ? addWeeks(this.currentDate, 1)
                            : addDays(this.currentDate, 1);
                    break;
                default:
                    break;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.scheduler-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 auto;

    .scheduler-body {
        display: flex;
        overflow: auto;
        line-height: 27px;
        font-size: 14px;
        background: white;
        border-radius: 10px;
        border: 1px solid var(--color-border);
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
        justify-content: center;
        padding: 20px;
        width: fit-content;
    }
}
</style>
